import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth';
import { SyncService } from 'src/app/core/services/sync.service';
import { EdaraSettingsService } from 'src/app/core/services/edara-settings.service';
import { SystemSettingsService } from '@core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  constructor(
    private router: Router,
    private authService: AuthService,
    private syncService: SyncService,
    private edaraSettingsService: EdaraSettingsService,
    private systemSettingsService: SystemSettingsService) {
    // Redirect to login if not logged in
    if (!this.authService.isAuthenticated()) {
      this.authService.userLoggedOut$.emit();
      this.router.navigate(['/login']);
    }
    else {
      this.checkEdaraSetup();
    }
  }

  private async checkEdaraSetup(): Promise<void> {
    const isSetupCompleted = await this.systemSettingsService.EdaraSetupCompleted();
    if (!isSetupCompleted) {
      this.router.navigate(['/system/edara-setup']);
    }
  }

}
